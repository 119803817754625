<div class="d-flex align-items-center auth register-bg-1 theme-one">
  <div class="row w-100">
    <div class="col-lg-4 mx-auto">
      <h2 class="text-center mb-4">Register</h2>
      <div class="auto-form-wrapper">
        <form action="#">
          <div class="form-group">
            <div class="input-group">
              <input type="text" class="form-control py-4" placeholder="Username">
              <div class="input-group-append">
                <span class="input-group-text">
                  <em class="mdi mdi-check-circle-outline"></em>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input type="password" class="form-control py-4" placeholder="Password">
              <div class="input-group-append">
                <span class="input-group-text">
                  <em class="mdi mdi-check-circle-outline"></em>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input type="password" class="form-control py-4" placeholder="Confirm Password">
              <div class="input-group-append">
                <span class="input-group-text">
                  <em class="mdi mdi-check-circle-outline"></em>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group d-flex justify-content-center">
            <div class="form-check form-check-flat mt-0">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" checked> I agree to the terms 
                <em class="input-helper"></em>
              </label>
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary submit-btn btn-block">Register</button>
          </div>
          <div class="text-block text-center my-3">
            <span class="text-small font-weight-semibold">Already have and account ?</span>
            <a href="login.html" class="text-black text-small">Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
