import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  accessToken: any;
  private GET_DASHBOARD_DATA = environment.apiURL + 'Content/GetAllContentCount';
  private SIGN_IN = environment.apiURL + 'User/SignIn';
  private ALL_USER_COUNT = environment.apiURL + 'User/AllUsersCount';

  constructor(private http: HttpClient) {
    this.accessToken = new BehaviorSubject('');
   }

  getDashboardData(industryId, roleId, startDate, endDate): Observable<any> {
    return this.http.get<any>(this.GET_DASHBOARD_DATA + '?IndustryId=' + industryId + '&RoleId=' + roleId + '&StartDate=' + startDate + '&EndDate=' + endDate);
  }

  signIn(): Observable<any> {
    return this.http.post<any>(this.SIGN_IN , '');
  }

  userCount(): Observable<any> {
    return this.http.get<any>(this.ALL_USER_COUNT);
  }

  getToken() {
    return this.accessToken;
  }

  setToken(values) {
    this.accessToken.next(values);
  }

}
