import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'oidc-client';
import { AuthService } from './core/services/auth.service';
import { Location } from '@angular/common';
import { DashboardService } from './pages/dashboard/services/dashboard.service';
import { tap , catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'star-admin-pro-angular';
  user: User;
  location: Location;
  // callback method to execute when the user logs out. 
  private userUnloadedCallback: () => void;
  // callback method to execute when the user logs in. 
  private userLoadedCallback: (user: User, router: Router, route: ActivatedRoute) => void;
  sideBar: boolean;

  constructor(private router: Router, private route: ActivatedRoute,
    public authService: AuthService, private dashboardService: DashboardService, location: Location) {
    this.location = location;
  }
  private isTokenInURL(url: string) {
    return url.includes("id_token") || url.includes("access_token");
  }

  async ngOnInit(): Promise<void> {
    this.userUnloadedCallback = this.onUserUnLoadedCallback(this);
    this.userLoadedCallback = this.onUserLoadedCallback(this);
    this.authService.addUserUnloadedCallback(this.userUnloadedCallback);
    this.authService.addUserLoadedCallback(this.userLoadedCallback);
    this.user = await this.authService.getUser();
    if (this.isTokenInURL(this.router.url)) {
      this.authService.handleCallBack();
    }
  }

  private onUserLoadedCallback(instance: AppComponent) {
    return async function (user: User, router, route) {
      localStorage.setItem('access_token', user.access_token);
      instance.user = user;
    }
  }

  signIn(): void {
    this.dashboardService
      .signIn()
      .pipe(catchError(err => of(err)
      ), tap((resp: any) => {
        if (resp.statusCode === 200) {
          this.dashboardService.setToken(resp.data.token);
           localStorage.setItem('access_token', resp.data.token);
           localStorage.setItem('user_id', resp.data.userId);
        }
      }),
      ).subscribe();
  }

  private onUserUnLoadedCallback(instance: AppComponent) {
    return async function () {
      instance.user = null;
    }
  }

  private async getUserJson() {
    return JSON.stringify(await this.authService.getUser());
  }
  login() {
    this.authService.loginRedirect().then();
  }
  logout() {
    this.authService.logoutRedirect().then();
  }
}
