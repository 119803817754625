import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-azure-login',
  templateUrl: './azure-login.component.html',
  styleUrls: ['./azure-login.component.scss']
})
export class AzureLoginComponent implements OnInit {
  user: User;
  // callback method to execute when the user logs out. 
  private userUnloadedCallback: () => void; 
  // callback method to execute when the user logs in. 
  private userLoadedCallback: (user: User, router: Router, route: ActivatedRoute) => void;

  constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute,) { }

  private isTokenInURL(url: string) {
    return url.includes("id_token") || url.includes("access_token");
  }

  async ngOnInit(): Promise<void> {
    this.userUnloadedCallback = this.onUserUnLoadedCallback(this);
    this.userLoadedCallback = this.onUserLoadedCallback(this);
    this.authService.addUserUnloadedCallback(this.userUnloadedCallback);
    this.authService.addUserLoadedCallback(this.userLoadedCallback);
    this.user = await this.authService.getUser(); 
    if (this.isTokenInURL(this.router.url)) {
        this.authService.handleCallBack(); 
    }
  }

  private onUserLoadedCallback(instance: AzureLoginComponent) {
    return async function (user: User, router, route) {
      instance.user = user; 
    }
  }
  private onUserUnLoadedCallback(instance: AzureLoginComponent) {
    return async function() {
      instance.user = null;
    }
  }

  private async getUserJson() {
    return JSON.stringify(await this.authService.getUser()); 
  }
  login() {
    this.authService.loginRedirect().then(); 
  }
  logout() {
    this.authService.logoutRedirect().then(); 
  }


}
