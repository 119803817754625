<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">

    <!-- Dashboard -->
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link1" [ngClass]="{'active': dashboardLink.isActive}"
        routerLink="/pages/main-dashboard/dashboard" routerLinkActive="active" #dashboardLink="routerLinkActive">
        <em class="menu-icon typcn typcn-document-text"></em>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <!-- User Management  -->
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link2"
        [ngClass]="{'active': generalUserLink.isActive || subscriptionLink.active }">
        <em class="menu-icon typcn typcn-coffee"></em>
        <span class="menu-title">User Management</span>
        <em class="fa fa-caret-down dropd_arrow"></em>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link2'">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/user-management/general-users" routerLinkActive="active"
              #generalUserLink="routerLinkActive">General Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/user-management/subscription-users" routerLinkActive="active"
              #subscriptionLink="routerLinkActive">Subscription Users</a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Content Management -->
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link3"
        [ngClass]="{'active': articlesLink.isActive || podcastsLink.isActive || videosLink.isActive || whitepapersLink.active || featureContentLink.active}">
        <em class="menu-icon typcn typcn-th-large-outline"></em>
        <span class="menu-title">Content Management</span>
        <em class="fa fa-caret-down dropd_arrow"></em>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link3'">
        <ul class="nav flex-column sub-menu">
          <!-- Below is the code for import functionality in content management -->
           
          <!-- <li class="nav-item">
            <a class="nav-link pointer importCont" routerLinkActive="active" #articlesLink="routerLinkActive">Import
              Content</a>
            <input class="file" type="file" title=" " (change)="onFileUpload($event)" (click)="$event.target.value=null"
              accept=".csv" />
          </li> -->
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/content-management/list-content']"
              [queryParams]="{type: 'Articles'}" routerLinkActive="active" #articlesLink="routerLinkActive">Articles</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/content-management/list-content']"
              [queryParams]="{type: 'Podcasts'}" routerLinkActive="active" #podcastsLink="routerLinkActive">Podcasts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/content-management/list-content']"
              [queryParams]="{type: 'Videos'}" routerLinkActive="active" #videosLink="routerLinkActive">Videos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/content-management/list-content']"
              [queryParams]="{type: 'Whitepapers'}" routerLinkActive="active"
              #whitepapersLink="routerLinkActive">Whitepapers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/content-management/featured-content']" routerLinkActive="active"
              #featureContentLink="routerLinkActive">Featured Content</a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Content Web Management -->
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link5" routerLink="/pages/links/content-links"
        routerLinkActive="active" [ngClass]="{'active': contentLinks.isActive}" #contentLinks="routerLinkActive">
        <em class="menu-icon typcn typcn-bell"></em>
        <span class="menu-title">Content Web Links</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link4"
        [ngClass]="{'active': legalsLink.isActive || faqsLink.isActive || contactLink.isActive}">
        <em class="menu-icon typcn typcn-coffee"></em>
        <span class="menu-title">App Content Management</span>
        <em class="fa fa-caret-down dropd_arrow"></em>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link4'">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/app-content-management/contact-details" routerLinkActive="active"
              #contactLink="routerLinkActive">Contact Details</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/app-content-management/faqs" routerLinkActive="active"
              #faqsLink="routerLinkActive">FAQs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/app-content-management/legals" routerLinkActive="active"
              #legalsLink="routerLinkActive">Legal Info</a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Presentation Management -->

    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link5"
        routerLink="/pages/presentation-management/listing" routerLinkActive="active"
        [ngClass]="{'active': notificationLink.isActive}" #notificationLink="routerLinkActive">
        <em class="menu-icon typcn typcn-bell"></em>
        <span class="menu-title">Presentation Management</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link6" routerLinkActive="active"
        [ngClass]="{'active': notificationLink.isActive}" #notificationLink="routerLinkActive">
        <em class="menu-icon typcn typcn-bell"></em>
        <span class="menu-title">Event Management</span>
        <em class="fa fa-caret-down dropd_arrow"></em>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link6'">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/event-management" routerLinkActive="active"
              #contactLink="routerLinkActive">Forum Events</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/event-management/non-forum-events-listing" routerLinkActive="active"
              #faqsLink="routerLinkActive">Non-Forum Events</a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Notification Management -->
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event)" id="nav-link7"
        [ngClass]="{'active': generalNotification.isActive || eventNotification.isActive}">
        <em class="menu-icon typcn typcn-bell"></em>
        <span class="menu-title">Notification Management</span>
        <em class="fa fa-caret-down dropd_arrow"></em>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link7'">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/notification-management/notifications" routerLinkActive="active"
              #generalNotification="routerLinkActive">General Notifications</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pages/notification-management/event-notifications" routerLinkActive="active"
              #eventNotification="routerLinkActive">Event Notifications</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>