import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxGaugeModule } from "ngx-gauge";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from "./app.component";
import { RegisterComponent } from "./sample-pages/register/register.component";
import { Page404Component } from "./sample-pages/page404/page404.component";
import { Page500Component } from "./sample-pages/page500/page500.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderInterceptor } from "./core/interceptors/header.interceptor";
import { ToastrModule } from "ngx-toastr";
import { AzureLoginComponent } from './auth/azure-login/azure-login.component';
import { PartialsModule } from "./partials/partials.module";
import { DatePipe } from "@angular/common";
import { NgChartsModule } from "ng2-charts";

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    Page404Component,
    Page500Component,
    AzureLoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    NgChartsModule,
    NgxGaugeModule,
    NgbModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    PartialsModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe,{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {}
