import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { DashboardService } from 'src/app/pages/dashboard/services/dashboard.service';
import Swal from 'sweetalert2';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  token: any;
  showOnlyOneTimePopup: boolean = true;
  constructor(private router: Router, private spinner: NgxSpinnerService, public authService: AuthService,
    private dashboardService: DashboardService) {
      this.token = localStorage.getItem('access_token');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }
    });
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    },
      error => {
        this.spinner.hide();
        if (error.status === 401) {
          localStorage.clear();
          this.authService.logoutRedirect().then();
        }
        if (error.status === 403) {
          if (this.showOnlyOneTimePopup) {
          Swal.fire({
            text: 'You are not configured as an administrator, you will be logged out within 5 secs automatically or by clicking on the OK button.',
            confirmButtonText: `OK`,
            confirmButtonColor: '#007DCC',
            showCloseButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.clear();
              this.authService.logoutRedirect().then();
            }
          });
          this.showOnlyOneTimePopup = false;
        }
          setTimeout(() => {
            localStorage.clear();
            this.authService.logoutRedirect().then();
          }, 5000);
        }
      }),
    );
  }

}
