export const environment = { 
  production: false, 
  apiURL: 'https://mobileapi.alexandergroup.com/api/', 
  oidcSettings: { 
    client_id: "0b2f484f-63d8-473f-a855-e5cb1c715731", 
    authority: "https://alexandergroupb2c.b2clogin.com/tfp/alexandergroupb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_TWO_STEP/v2.0", 
    response_type: "id_token token", 
    post_logout_redirect_uri: "https://mobileadmin.alexandergroup.com/login", 
    loadUserInfo: false, 
    // redirect_uri: "http://localhost:4200/pages/main-dashboard/dashboard", 
    redirect_uri: "https://mobileadmin.alexandergroup.com/pages/main-dashboard/dashboard", 
    silent_redirect_uri: "https://mobileadmin.alexandergroup.com/login", 
    response_mode: "fragment", 
    scope: "https://alexandergroupb2c.onmicrosoft.com/7e29d16d-5232-4b0d-a94a-b879a3701b06/tasks.read openid profile" 
  }, 

  changePasswordodicSettings: { 
    client_id: "0b2f484f-63d8-473f-a855-e5cb1c715731", 
    signupSigninPolicy: "B2C_1A_SIGNUP_SIGNIN_TWO_STEP", 
    tenantName: "alexandergroupb2c", 
    response_type: "id_token token", 
    redirect_uri: "https://mobileadmin.alexandergroup.com/pages/main-dashboard/dashboard", 
    loadUserInfo: false, 
    response_mode: "fragment", 
    scope: "https://alexandergroupb2c.onmicrosoft.com/7e29d16d-5232-4b0d-a94a-b879a3701b06/tasks.read openid profile", 
    editProfilePolicy: "B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE", 
    resetPasswordPolicy: "B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE" 
  } 
}; 