import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {

  importMessageValue: any;
  private GET_TYPES_URL = environment.apiURL + 'Type/GetAllTypes';
  private GET_CONTENT_URL = environment.apiURL + 'Content/FilterContentData';
  private GET_AUTHOR_URL = environment.apiURL + 'Content/GetAllAuthor';
  private GET_INDUSTRY_URL = environment.apiURL + 'Content/GetAllIndustry';
  private GET_ROLES_URL = environment.apiURL + 'Role/GetAllRoles';
  private GET_CONTENT_DATA_BY_ID = environment.apiURL + 'Content/GetView';
  private ADD_EDIT_CONTENT = environment.apiURL + 'Content/AddEditContent';
  private UPDATE_PUBLISH = environment.apiURL + 'Content/UpdatePublish';
  private GET_COUNTRY_URL = environment.apiURL + 'User/GetCountryList';
  private IMPORT_CONTENT = environment.apiURL + 'Content/importcsv';
  private GET_FEATURED_CONTENT_LIST = environment.apiURL + 'Content/GetFeaturedContent';
  private ADD_FEATURE_CONTENT = environment.apiURL + 'Content/AddFeaturedContent';
  private SET_SEQUENCE = environment.apiURL + 'Content/AddSequenceOfContent';

  constructor(private http: HttpClient) {
    this.importMessageValue = new BehaviorSubject('');
   }

  getTypes(): Observable<any> {
    return this.http.get<any>(this.GET_TYPES_URL);
  }

  getContent(body:any): Observable<any> {
    return this.http.get<any>(this.GET_CONTENT_URL + '?TypeId=' + body.typeId + '&SearchText=' + body.searchValue + '&Author=' + body.author + '&IndustryId=' + body.industry + '&RoleId=' + body.role + '&isPremium=' + body.isPremium + '&StartDate=' + body.startDate+ '&EndDate=' + body.endDate+ '&PageSize=10000' +'&sortbyfeature=' + body.sortbyfeature + '&sortBy=' + body.sortBy + '&SortOrder=' + body.sortOrder);
  }

  getAuthors(): Observable<any> {
    return this.http.get<any>(this.GET_AUTHOR_URL);
  }

  getIndustries(): Observable<any> {
    return this.http.get<any>(this.GET_INDUSTRY_URL);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(this.GET_ROLES_URL);
  }

  getCountry(): Observable<any> {
    return this.http.get<any>(this.GET_COUNTRY_URL);
  }

  updatePublish(publishData): Observable<any> {
    return this.http.put<any>(this.UPDATE_PUBLISH + '?id=' + publishData?.id + '&isUnPublish=' + publishData?.isUnPublish, publishData);
  }

  getContentDataById(id): Observable<any> {
    return this.http.get<any>(this.GET_CONTENT_DATA_BY_ID + '?id=' + id);
  }

  addEditContent(data): Observable<any> {
    return this.http.post<any>(this.ADD_EDIT_CONTENT , data);
  }

  importCsv(file): Observable<any> {
    return this.http.post<any>(this.IMPORT_CONTENT , file);
  }

  featuredContentList(): Observable<any> {
    return this.http.get<any>(this.GET_FEATURED_CONTENT_LIST);
  }

  addFeatureContent(body): Observable<any> {
    return this.http.put<any>(this.ADD_FEATURE_CONTENT , body);
  }

  setSequence(body): Observable<any> {
    return this.http.post<any>(this.SET_SEQUENCE , body);
  }

  getImportMessage() {
    return this.importMessageValue;
  }

  setImportMessage(values) {
    this.importMessageValue.next(values);
  }

}
