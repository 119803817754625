<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
    <a class="navbar-brand brand-logo" routerLink="/dashboard">
      <img src="assets/images/AG_logo.svg" alt="logo" /> </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
      (click)="toggleIconOnlySidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <ul class="navbar-nav navbar-nav-right">
      <li class="nav-item dropdown d-none d-xl-inline-flex" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle>
          <span>Admin</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <a class="dropdown-item" (click)="resetPasswordRoute()">Change Password<em class="dropdown-item-icon ti-comment-alt"></em></a>
          <a class="dropdown-item" (click)="logout()">Logout<em class="dropdown-item-icon ti-location-arrow"></em></a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center ml-auto pr-0" type="button"
      (click)="toggleRightSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>
