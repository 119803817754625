<div class="container-fluid bg-image">
    <div class="row">
        <div class="col-md-12">
                <div class="image-cont text-center">
                    <h1 class="image_header">Welcome To Admin Panel</h1>
                    <div>
                        <img src="assets/images/AG_logo.svg" class="img_sz" alt="">
                    </div>
                <button class="login-btn mt-4" (click)="login()">LOGIN</button>
                </div>
        </div>
    </div>
</div>