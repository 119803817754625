import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/core/services/auth.service";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  resetPasswordPolicy = environment.changePasswordodicSettings.resetPasswordPolicy;
  redirect_uri = environment.changePasswordodicSettings.redirect_uri;
  client_id = environment.changePasswordodicSettings.client_id;
  tenantName = environment.changePasswordodicSettings.tenantName;

  toggleRightSidebar() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleIconOnlySidebar() {
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      document.querySelector("body").classList.add("sidebar-icon-only");
    } else {
      document.querySelector("body").classList.remove("sidebar-icon-only");
    }
  }

  constructor(config: NgbDropdownConfig, private router: Router, public authService: AuthService) {
    config.placement = "bottom-right";
  }

  navigateToNotifications() {
    this.router.navigate(['/notification-management/notification-list']);
  }

  navigateToProfile() {
    this.router.navigate(['/my-profile/admin']);
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to logout ?`,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#007DCC',
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.logoutRedirect().then();
        localStorage.clear();
      }
    })
  }

  resetPasswordRoute() {
    return this.buildRoute({ policyName: this.resetPasswordPolicy });
  }
  private buildRoute(options): string {
    const url = new URL(
      `https://${this.tenantName}.b2clogin.com/${this.tenantName}.onmicrosoft.com/oauth2/v2.0/authorize`
    );
    url.searchParams.append('p', options.policyName);
    url.searchParams.append('client_id', this.client_id);
    url.searchParams.append('nonce', 'defaultNonce');
    url.searchParams.append(
      'redirect_uri',
      options.redirectUri ? options.redirectUri : this.redirect_uri
    );
    url.searchParams.append('scope', options.scope ? options.scope : 'openid');
    url.searchParams.append(
      'response_type',
      options.responseType ? options.responseType : 'id_token'
    );
    if (options.promptLogin) {
      url.searchParams.append('prompt', 'login');
    }
    window.open(url.href, '_self')
    return url.href;
  }

}
