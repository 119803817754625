import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ContentManagementService } from "src/app/pages/content-management/services/content-management.service";
import { tap , catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from "@angular/router";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {

  constructor(private contentService: ContentManagementService, private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    const body = document.querySelector("body");

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

  }

  public parentId = "";
  clickedMenu(event) {
    var target = event.currentTarget;
    let parentId = target.id;
    if (parentId == this.parentId) {
      this.parentId = "";
    } else {
      this.parentId = target.id;
    }
  }

  // Function for importing content

  // onFileUpload(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     this.contentService
  //       .importCsv(formData)
  //       .pipe(catchError(err => of(err)
  //       ), tap((resp: any) => {
  //         if (resp.statusCode === 200) {
  //           this.contentService.setImportMessage(resp);
  //           this.router.navigate(['/pages/message']);
  //         } else {
  //           this.toastr.error(resp.error.message);
  //         }
  //       }),
  //       ).subscribe();
  //   }
  // }

}
