import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { DashboardService } from 'src/app/pages/dashboard/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { tap , catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _userManager: UserManager; 

  constructor(private dashboardService: DashboardService) { 
      this.instantiate(); 
  }

  public async loginRedirect(): Promise<any> {
      return this._userManager.signinRedirect(); 
  }

  public async loginSilent(): Promise<User> {
    return this._userManager.signinSilent(); 
  }


  public async logoutRedirect(): Promise<any> {
      await this._userManager.signoutRedirect();
      await this._userManager.clearStaleState(); 
  }

  public addUserUnloadedCallback(callback): void {
      this._userManager.events.addUserUnloaded(callback);
  }

  public removeUserUnloadedCallback(callback): void {
      this._userManager.events.removeUserLoaded(callback);
  }

  public addUserLoadedCallback(callback): void {
      this._userManager.events.addUserLoaded(callback);
  }

  public removeUserLoadedCallback(callback): void {
      this._userManager.events.removeUserLoaded(callback);
  }

  public async accessToken(): Promise<string> {
      var user = await this._userManager.getUser();
      if (user == null) {
          throw new Error("User is not logged in");
      }
      return user.access_token;
  }


  public async getUser(): Promise<User> {
      return this._userManager.getUser();
  }

  public async handleCallBack() {
      var user = await this._userManager.signinRedirectCallback(); 
      localStorage.setItem('access_token' , user.access_token);
      setTimeout(() => {
      this.dashboardService
      .signIn()
      .pipe(catchError(err => of(err)
      ), tap((resp: any) => {
        if (resp.statusCode === 200) {
           localStorage.setItem('user_id', resp.data.userId);
        }
      }),
      ).subscribe();
    })
  }

  public instantiate() {
      var settings = environment.oidcSettings;
      this._userManager = new UserManager(settings);
  }
}