import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RegisterComponent } from "./sample-pages/register/register.component";
import { Page404Component } from "./sample-pages/page404/page404.component";
import { Page500Component } from "./sample-pages/page500/page500.component";
import { AzureLoginComponent } from "./auth/azure-login/azure-login.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" , 
},
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'login',
    component: AzureLoginComponent,
  },
  { path: "register", component: RegisterComponent },
  { path: "404-page", component: Page404Component },
  { path: "500-page", component: Page500Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
